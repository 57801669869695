import {useRef} from "react";
import {Dialog,Button,DialogTitle,DialogContent,DialogActions,Box,Typography} from '@mui/material';
import Paper from "@mui/material/Paper";

const PaperComponent=(props)=>{
    const nodeRef=useRef(null);
    return (
        <Paper sx={{
            backgroundImage:`linear-gradient(rgba(9, 14, 16, 0),#02294F)`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            color:'white',
          }} style={{height:'65vh'}} ref={nodeRef} {...props} />
    );
  }

const TermsOfService=({termsOfServiceCallback})=>{
    const ref=useRef();

    const handleClickClose=()=>{
        termsOfServiceCallback();
    }

    return (
        <Dialog fullWidth={true} maxWidth={'lg'} open={true} PaperComponent={PaperComponent} onClose={handleClickClose}>
             <DialogTitle>Terms of Service</DialogTitle>
                <DialogContent>
                    <Box component="form" noValidate autoComplete="off">
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    Effective Date: 23.10.2023
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    1. Acceptance of Terms
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    By accessing or using the website located at https://coda.engineering/ ("the Site") operated by Coda Engineering ("we," "our," "us"), you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree with these Terms, please do not use the Site.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    2. Use of the Site
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    You agree to use the Site in a manner consistent with all applicable laws and regulations. You are prohibited from using the Site to engage in any activity that is illegal, harmful, or otherwise objectionable, as determined by us.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    3. Intellectual Property
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    All content on the Site, including text, graphics, logos, images, and software, is the property of Coda Engineering or its content suppliers and is protected by applicable intellectual property laws. Unauthorized use of any content is strictly prohibited.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    4. User Content
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    If you submit or post any content to the Site, such as comments, feedback, or other materials ("User Content"), you grant Coda Engineering a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, modify, and display such User Content. You are solely responsible for the content you submit.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    5. Links to Third-Party Sites
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    The Site may contain links to third-party websites. These links are provided for your convenience only. We do not endorse or assume any responsibility for the content or practices of these third-party sites. Accessing third-party sites is at your own risk.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    6. Limitation of Liability
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    To the fullest extent permitted by law, Coda Engineering shall not be liable for any damages arising out of or in connection with your use of the Site. This includes, without limitation, direct, indirect, incidental, punitive, and consequential damages.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    7. Disclaimer of Warranties
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    The Site is provided on an "as is" and "as available" basis. Coda Engineering makes no warranties, express or implied, regarding the operation or content of the Site. We do not warrant that the Site will be error-free, secure, or available at all times.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    8. Indemnification
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    You agree to indemnify, defend, and hold harmless Coda Engineering and its affiliates from any claims, liabilities, damages, or expenses arising out of your use of the Site or any violation of these Terms.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    9. Termination
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    We reserve the right to terminate or suspend your access to the Site at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users of the Site, us, or third parties.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    10. Governing Law
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    These Terms shall be governed by and construed in accordance with the laws of Finland, without regard to its conflict of law principles. Any legal action arising out of these Terms shall be brought exclusively in the courts located in Helsinki.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    11. Changes to These Terms
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    We may update these Terms from time to time. The updated version will be indicated by the "Effective Date" at the top of this page. Your continued use of the Site after any changes signifies your acceptance of the new Terms.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    13. Contact Us
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    If you have any questions about these Terms, please contact us at:
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle2" sx={{color:'grey.400'}}>Coda Engineering</Typography>
                    <Typography align="left" paragraph variant="subtitle2" sx={{color:'grey.400'}}>contact@coda.engineering</Typography>
                    </Box>
                </DialogContent>
            <DialogActions>
                <Button className="btn" onClick={handleClickClose} variant="contained">Close</Button>
             </DialogActions>
        </Dialog>
    );
}

export default TermsOfService;
