import * as React from 'react';
import {useState} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import contact_globe from '../assets/contact_globe.jpeg';
import coda_logo from '../assets/coda_logo_white_small.png';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

const logoStyle = {
  width: '140px',
  height: 'auto',
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="https://coda.engineering/">Coda Engineering</Link>
      {' '+new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const [isPrivacyPolicyOpen,SetisPrivacyPolicyOpen]=useState(false);
  const [isTermsOfServiceOpen,SetisTermsOfService]=useState(false);
  
  const privacyPolicyCallback=()=>{
    SetisPrivacyPolicyOpen(old=>false);
  }
  const termsOfServiceCallback=()=>{
    SetisTermsOfService(old=>false);
  }

  return (
    <Box
      id='footer'
      style={{paddingTop:'10px'}}
      sx={{
        backgroundImage:`linear-gradient(rgba(9, 14, 16, 0),#02294F)`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        pt:{xs:4,sm:12},
        pb:{xs:8,sm:16},
        color:'white',
      }}>
        {isPrivacyPolicyOpen&&<PrivacyPolicy privacyPolicyCallback={privacyPolicyCallback}/>}
        {isTermsOfServiceOpen&&<TermsOfService termsOfServiceCallback={termsOfServiceCallback}/>}
        <Box
            sx={{
              filter: 'brightness(50%)',
              //transform: 'scale(-1, -1)',
              width:'100%',
              boxShadow:0,
              position:'absolute',
              left:0,
              right:0,
              marginTop:'40px',
              height:'55%',
              backgroundImage: `url(${contact_globe})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              opacity:0.3,
              zIndex:-1,
            }}/>
      <Container
        sx={{
          position:'relative',
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          gap:{xs:3,sm:6},
        }}>
        <Box
          sx={{
            width:{xs:'100%',sm:'100%',md:'60%'},
            textAlign:{xs:'center',sm:'center',md:'center'},
          }}>
          <Typography component="h2" variant="h4">
            Contact
          </Typography>
          <Typography align="left" paragraph variant="body1" sx={{color:'grey.400'}}>
          Ready to transform your business with cutting-edge digital solutions? Whether you're looking to streamline operations, enhance customer relationships, or explore new digital opportunities, we're here to help. Reach out to our team of experts today, and let's start building the future of your business together.
          </Typography>
          <Typography align="left" paragraph variant="body1" sx={{color:'grey.400'}}>
          Please contact us on the e-mail address below:
          </Typography>
          <Link href="mailto:contact@coda.engineering" underline="hover">
            {'contact@coda.engineering'}
          </Link>
          
        </Box>
        <img src={coda_logo} style={{width:'4%',height:'auto'}} alt="Coda Engineering"/>
      
      <Box
        sx={{
          display:'flex',
          justifyContent:'space-between',
          pt:{xs:2,sm:4},
          width:'100%',
          borderTop:'1px solid',
          borderColor:'divider',
        }}>
        <div>
          <Link onClick={()=>{SetisPrivacyPolicyOpen(old=>true);}} color="text.secondary" href="#">
            Privacy Policy
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link onClick={()=>{SetisTermsOfService(old=>true);}} color="text.secondary" href="#">
            Terms of Service
          </Link>
          <Copyright/>
        </div>
      </Box>
    </Container>
    </Box>
  );
}