import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import coda_globe from '../assets/coda_globe.png';
import coda_logo from '../assets/coda_logo_white_small.png';

export default function Coda(){
  return(
      <Container
        id="coda"
        alignItems="center"
        sx={{
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          pt:{xs:5,sm:8},
          zIndex: 2,
        }}>
          <Box
            sx={{
              filter: 'brightness(50%)',
              transform: 'scale(-1, -1)',
              width:'100%',
              boxShadow:0,
              position:'absolute',
              top:0,
              left:0,
              right:0,
              marginTop:'50px',
              height:'35%',
              backgroundImage: `url(${coda_globe})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              opacity:0.3,
              zIndex:-1,
            }}/>
          <Stack spacing={2} alignItems="center" useFlexGap direction={{xs:'column',sm:'row'}}>
            <div style={{textAlign:'center'}}>
            <img src={coda_logo} style={{width:'11%',height:'auto'}} alt="Coda Engineering"/>
            <Typography
              variant="h1"
              sx={{
                display:'flex',
                flexDirection:{xs:'column',md:'row'},
                alignSelf:'center',
                textAlign:'center',
                fontSize:'clamp(3.5rem, 10vw, 4rem)',
              }}>
              Coda Engineering
            </Typography>
            </div>
          </Stack>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{alignSelf:'center',width:{sm:'100%',md:'80%'}}}>
              IT Consulting & Digital Transformation Services
          </Typography>
      </Container>
  );
}