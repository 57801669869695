import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
//import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
//import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import coda_globe from '../assets/coda_globe.png';
import contact_monitor from '../assets/contact_monitor.jpeg';

export default function About() {
  return (
    <Box
      id="about"
      style={{paddingTop:'10px'}}
      sx={{
        backgroundImage:`linear-gradient(rgba(9, 14, 16, 0),#02294F)`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        pt:{xs:4,sm:12},
        pb:{xs:8,sm:16},
        color:'white',
      }}>
        <Box
            sx={{
              filter: 'brightness(50%)',
              //transform: 'scale(-1, -1)',
              width:'100%',
              boxShadow:0,
              position:'absolute',
              left:0,
              right:0,
              marginTop:'40px',
              height:'50%',
              backgroundImage: `url(${contact_monitor})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              opacity:0.2,
              zIndex:-1,
            }}/>
      <Container
        sx={{
          position:'relative',
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          gap:{xs:3,sm:6},
        }}>
        <Box
          sx={{
            width:{xs:'100%',sm:'100%',md:'60%'},
            textAlign:{xs:'center',sm:'center',md:'center'},
          }}>
          <Typography component="h2" variant="h4">
          Coda Engineering
          </Typography>
          <Typography align="left" paragraph variant="body1" sx={{color:'grey.400'}}>
          At Coda Engineering, we specialize in delivering tailored information technology and digitalization services, with a focus on budget-friendly custom CRM and sales platforms. Based in Helsinki, our mission is to empower businesses by identifying and implementing digital solutions that enhance performance, save time, and replace outdated systems.
          </Typography>
          <Typography align="left" paragraph variant="body1" sx={{color:'grey.400' }}>
          What sets us apart is our team of seasoned professionals, boasting an average of 20+ years of experience in technology consulting. This deep expertise enables us to provide innovative, cost-effective solutions that precisely meet our clients' needs. Whether you're looking to streamline your operations or modernize your tech stack, Coda Engineering is your trusted partner in navigating the digital landscape.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}