import './App.css';
import LandingPage from "./LandingPage"
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";

function App(){


  return(
    <div className="App">
      <Router>
          <Routes>
            <Route exact path="/" element={<LandingPage/>}/>
          </Routes>
      </Router>
    </div>
  );
}

export default App;
