import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import {ThemeProvider,createTheme} from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import About from './components/About';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Coda from './components/Coda';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent';

export default function LandingPage(){
  const [mode,setMode]=React.useState('dark');
  const defaultTheme=createTheme({palette:{mode},typography: {
    "fontFamily": `"Roboto"`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   }});
  return(
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline/>
      <AppAppBar/>
      <Box>
        <Coda/>
        <Services/>
        <Testimonials/>
        <About/>
        <Footer/>
      </Box>
      <CookieConsent />
    </ThemeProvider>
  );
}