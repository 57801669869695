import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function CookieConsent() {
  const [showBanner, setShowBanner] = useState(!localStorage.getItem('cookieConsent'));

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
  };

  return (
    showBanner && (
      <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'black', color: 'white', p: 2, textAlign: 'center' }}>
        <Typography variant="body2">
          We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
        </Typography>
        <Button onClick={handleAccept} sx={{ color: 'white', ml: 2 }}>
          Accept
        </Button>
      </Box>
    )
  );
}

export default CookieConsent;