import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import coda_logo from '../assets/coda_logo_white_small.png';

function AppAppBar() {
  const [open,setOpen] = React.useState(false);
  const toggleDrawer=(newOpen)=>()=>{setOpen(newOpen);};

  const CodaLogo=(size)=>{
    return <img src={coda_logo} style={{width:size,height:'auto',cursor:'pointer'}} alt="Coda Engineering" onClick={()=>scrollToSection('coda')}/>
  }

  const scrollToSection=(sectionId)=>{
    const sectionElement=document.getElementById(sectionId);
    const offset=sectionId==='services'?5:45;
    if(sectionElement){
      const targetScroll=sectionElement.offsetTop-offset;
      sectionElement.scrollIntoView({behavior:'smooth'});
      window.scrollTo({top:targetScroll,behavior:'smooth',});
      setOpen(false);
    }
  };

  return(
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow:0,
          bgcolor:'transparent',
          backgroundImage:'none',
          mt:1,
        }}>
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            style={{minHeight:'10px'}}
            sx={(theme)=>({
              display:'flex',
              alignItems:'center',
              justifyContent:'space-between',
              flexShrink:0,
              bgcolor:'rgba(0, 0, 0, 0.4)',
              backdropFilter:'blur(24px)',
              borderColor:'divider',
              boxShadow:
                theme.palette.mode='0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}>
            <Box
              sx={{
                flexGrow:1,
                display:'flex',
                alignItems:'center',
                ml:'-18px',
                px:0,
                }}>
              <Box sx={{display:{xs:'none',md:'flex'}}} style={{minHeight:'0px',}}>
                {CodaLogo('4%')}
                <MenuItem onClick={() => scrollToSection('services')} sx={{py:'6px',px:'12px'}}>
                  <Typography variant="body2" color="text.primary">Services</Typography>
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('testimonials')}sx={{py:'6px',px:'12px'}}>
                  <Typography variant="body2" color="text.primary">Testimonials</Typography>
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('about')}sx={{py:'6px',px:'12px'}}>
                  <Typography variant="body2" color="text.primary">About us</Typography>
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('footer')} sx={{py:'6px',px:'12px'}}>
                  <Typography variant="body2" color="text.primary">Contact</Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box sx={{display:{sm:'',md:'none'}}}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{minWidth:'30px',p:'4px'}}>
                <MenuIcon/>
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    p:2,
                    boxShadow:0,
                    backgroundImage:`linear-gradient(rgba(9, 14, 16, 0),#02294F)`,
                    backgroundSize:'100% 100%',
                    backgroundRepeat:'no-repeat',
                    flexGrow: 1,
                  }}>
                  <MenuItem onClick={() => scrollToSection('coda')}>{CodaLogo('6%')}</MenuItem>
                  <MenuItem onClick={() => scrollToSection('services')}>Services</MenuItem>
                  <MenuItem onClick={() => scrollToSection('testimonials')}>Testimonials</MenuItem>
                  <MenuItem onClick={() => scrollToSection('about')}>About us</MenuItem>
                  <MenuItem onClick={() => scrollToSection('footer')}>Contact</MenuItem>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default AppAppBar;