import {useRef} from "react";
import {Dialog,Button,DialogTitle,DialogContent,DialogActions,Box,Typography} from '@mui/material';
import Paper from "@mui/material/Paper";

const PaperComponent=(props)=>{
    const nodeRef=useRef(null);
    return (
        <Paper sx={{
            backgroundImage:`linear-gradient(rgba(9, 14, 16, 0),#02294F)`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            color:'white',
          }} style={{height:'65vh'}} ref={nodeRef} {...props} />
    );
  }

const PrivacyPolicy=({privacyPolicyCallback})=>{
    const ref=useRef();

    const handleClickClose=()=>{
        privacyPolicyCallback();
    }

    return (
        <Dialog fullWidth={true} maxWidth={'lg'} open={true} PaperComponent={PaperComponent} onClose={handleClickClose}>
             <DialogTitle>Privacy Policy</DialogTitle>
                <DialogContent>
                    <Box component="form" noValidate autoComplete="off">
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    Effective Date: 23.10.2023
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    1. Introduction
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    Welcome to Coda Engineering ("we," "our," "us"). We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information about visitors to our website, https://coda.engineering/ ("the Site").
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    2. Information We Collect
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    We may collect the following types of information when you visit our Site:
                    <li>Personal Information: If you fill out a contact form or subscribe to our newsletter, we may collect your name, email address, and any other information you provide.</li>
                    <li>Non-Personal Information: We may collect non-personal information about your visit to our Site, such as your IP address, browser type, and how you navigate through the Site.</li>
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    3. How We Use Your Information
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    The information we collect is used to:
                    <li>Respond to your inquiries or requests.</li>
                    <li>Improve our website and services.</li>
                    <li>Communicate with you, if you have opted to receive our communications.</li>
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    4. Cookies and Tracking Technologies
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    We may use cookies or similar tracking technologies to enhance your experience on our Site. You can set your browser to refuse all or some cookies, but this may limit your ability to use certain features of the Site.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    5. Sharing of Information
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    We do not sell, trade, or otherwise transfer your personal information to outside parties unless we provide you with advance notice, except as described below:
                    <li>Service Providers: We may share information with trusted third parties who assist us in operating our website and conducting our business, so long as those parties agree to keep this information confidential.</li>
                    <li>Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    6. Data Security
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    7. Third-Party Links
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    Our Site may contain links to third-party websites. We are not responsible for the privacy practices or the content of these third-party sites. We encourage you to review their privacy policies.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    8. Children's Privacy
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    Our Site is not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    9. Changes to This Privacy Policy
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    We may update this Privacy Policy from time to time. The updated version will be indicated by the "Effective Date" at the top of this page. We encourage you to review this page periodically for any changes.
                    </Typography>
                    <Typography align="left" paragraph variant="h6" sx={{color:'grey.400'}}>
                    10. Contact Us
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle1" sx={{color:'grey.400'}}>
                    If you have any questions about this Privacy Policy, please contact us at:
                    </Typography>
                    <Typography align="left" paragraph variant="subtitle2" sx={{color:'grey.400'}}>Coda Engineering</Typography>
                    <Typography align="left" paragraph variant="subtitle2" sx={{color:'grey.400'}}>contact@coda.engineering</Typography>
                    </Box>
                </DialogContent>
            <DialogActions>
                <Button className="btn" onClick={handleClickClose} variant="contained">Close</Button>
             </DialogActions>
        </Dialog>
    );
}

export default PrivacyPolicy;
