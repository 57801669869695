import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import CloudRoundedIcon from '@mui/icons-material/CloudRounded';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Process Digitalisation',
    description:
      'Transforming workflows into efficient, automated digital solutions',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Custom-Built Systems',
    description:
      'Tailored solutions designed to fit your unique needs.',
  },
  {
    icon: <CloudRoundedIcon />,
    title: 'Cloud Agnostic',
    description:
      'Flexible solutions that work seamlessly across any cloud provider.',
  },
  {
    icon: <LocalOfferRoundedIcon />,
    title: 'Cost-effective',
    description:
      'Delivering high value and results while minimizing expenses.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Reliable Support',
    description:
      'Consistent, trustworthy assistance you can count on anytime.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Data Quality',
    description:
      'Ensuring accuracy, consistency, and reliability of your data.',
  },
];

export default function Services() {
  return (
    <Box
      id="services"
      style={{paddingTop:'45px'}}
      sx={{
        backgroundImage:`linear-gradient(rgba(9, 14, 16, 0),#02294F)`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        pt:{xs:4,sm:12},
        pb:{xs:8,sm:16},
        color:'white',
      }}>
      <Container
        sx={{
          position:'relative',
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          gap:{xs:3,sm:6},
        }}>
        <Box
          sx={{
            width:{xs:'100%',sm:'100%',md:'60%'},
            textAlign:{xs:'center',sm:'center',md:'center'},
          }}>
          <Typography component="h2" variant="h4">
            Services
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Explore why our product stands out: adaptability, durability,
            user-friendly design, and innovation. Enjoy reliable customer support and
            precision in every detail.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p:3,
                  height:'100%',
                  border:'1px solid',
                  borderColor:'grey.800',
                  background:'transparent',
                  backgroundColor:'#0F202F',
                }}>
                <Box sx={{opacity:'50%'}}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>{item.title}</Typography>
                  <Typography variant="body2" sx={{color:'grey.400'}}>{item.description}</Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}